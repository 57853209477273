import React from "react";
import ".//Header.css";
import { Link } from "react-router-dom";
import {connect} from "react-redux";

function Header(props : any) {

    function handleChange(e : any) {
        props.searchValue(e.target.value)
    }

    return (
        <div className="header">
            <Link to="/">
                <a href="/" className="logo">Storegg</a>
            </Link>
            <div className="floating-search">
                <input onChange={handleChange} value={props.inputValue} placeholder="Search Item" className="floating-search-input" />
            </div>
            <div className="header-right">
                <a href="/" className="active" >
                    <img width="30" height="25" src={require("../../Assets/silver-coin.png")} alt="" />
                    {props.coin}
                </a>
                <img className="profileImg" width="50" height="50" src={require("../../Assets/user.png")} alt="" />
            </div>
        </div>
    );
}

const mapStateToProps = (state : any) => {
    return {
        coin: state.coin
    }
}

export default connect(mapStateToProps)(Header);