import React from "react";
import Button from "../Button"
import Modal from 'react-bootstrap/Modal';
import { ModalFooter } from "react-bootstrap";
import "./Alert.css";

interface propsAlert {
    showAlert: boolean;
    handleCloseAlert: VoidFunction;
    image: string;
    title: string;
    content: string;
}

function Alert(props : propsAlert) {
    return(
        <Modal show={props.showAlert} onHide={props.handleCloseAlert} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <div className="alert-body">
                    <img className="alert-image" width="150" height="150" src={props.image} alt="" />
                    <div>
                        <h4>{props.title}</h4>
                    </div>
                    <div>
                        <p>{props.content}</p>
                    </div>
                </div>
                {/* <Container>
                    <Row>
                    <Col xs={12} md={4}>
                        <img width="150" height="150" src="" alt="" />
                    </Col>
                    <Col xs={12} md={6}>
                        <p><b>halo</b></p>
                        <p>ini desc didjijijdwjwd</p>
                    </Col>
                    </Row>
                </Container> */}
            </Modal.Body>
            <ModalFooter>
                <Button text="OK" color="info" onClick={props.handleCloseAlert} margin="0 45% 0 0"/>
            </ModalFooter>
        </Modal>
    )
}

export default Alert;