import React from "react";

interface productsProps {
    id: any;
    onDetail: any;
    image : string;
    title: string;
    price: BigInteger;
    desc: string;
}

function GridView(props : productsProps) {

    function handleClick() {
        props.onDetail(props.id);
    }

    return(
    <div onClick={handleClick} className="card" style={{width: "16.5rem", height: "25rem", cursor: "pointer"}}>
        <img className="card-img-top" width="300" height="130" src={props.image} alt="" />
        <div className="card-body">
            <h5 className="card-title">{props.title}</h5>
            <p><b>${props.price}</b></p>
            <b></b>
            <p className="card-text">{props.desc}</p>
        </div>
    </div>
    )
}

export default GridView;