import React from "react";
import ButtonBootsrap from "react-bootstrap/Button";

interface ButtonProps {
    text: string;
    color: string;
    onClick: VoidFunction;
    margin: string;
}

function Button(props : ButtonProps) {

    return (<ButtonBootsrap onClick={props.onClick} variant={props.color} style={{margin:props.margin}}>{props.text}</ButtonBootsrap>)
}

export default Button;