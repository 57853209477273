import React, { useState } from 'react';
import Header from "../../components/Header/Header";
import './MyProduct.css';
import {connect} from "react-redux";
import GridView from "../../components/GridView";
import DetailProduct from "../../components/DetailProduct";
import ListView from '../../components/ListView/ListView';
import gridIcon from '../../Assets/grid.png';
import listIcon from '../../Assets/list.png';
import FloatSubApp from '../../components/FloatSubApp';

let indexProduct: number;
let dataDetailProduct : any;
let myProducts : any;


function MyProduct(props: any) {

    const [show, setShow] = useState(false);
    const [detailProduct, setDetailProduct] = useState<any>({});
    const [listView, setListView] = useState(true);
    const [listImage, setListImage] = useState(gridIcon);
    const [searchValue, setSearchValue] = useState<any>("");
    const [myProductTemp, setMyProductTemp] = useState<any[]>([]);
    dataDetailProduct = detailProduct;
    
    if (searchValue === "") {
        myProducts = props.myProduct;
    } else {
        myProducts = myProductTemp;
    }



    
    
    const handleClose = () => setShow(false);

    function handleShow(id: any) {
        setDetailProduct(props.myProduct[id]);
        setShow(true);
        indexProduct = id;      
      }

    function handleSearch(searchValues : any) {
        setSearchValue(searchValues);
        

        if(searchValue !== "") {
            let searchProductData = props.myProduct.filter((value: { title: string; }) => value.title.toLowerCase().includes(searchValues.toLowerCase()));
            setMyProductTemp(searchProductData);
        }
        // useEffect(() => {

            
            
            // console.log(searchProductData);
            
            // console.log(productData.filter(value => value.title.toLowerCase().includes("Fjallraven")));
            // console.log(productData[0].title.toLowerCase().includes(searchValue.toLowerCase()));
            
            // setProductData(searchProductData)
            // })
    }

    function handleView() {
    if(listView) {
        setListView(false);
        setListImage(listIcon);
    } else {
        setListView(true);
        setListImage(gridIcon);
    }
    }

      function handleAlert(show: boolean) {
        setShow(show);
      }

    return(
        <div className="myProduct">
            <Header searchValue={handleSearch} inputValue={searchValue} />
            <div className="mainPage">
            <DetailProduct 
            show={show} 
            onHide={handleClose}
            handleClick={props.deleteProduct}
            title={detailProduct.title}
            image={detailProduct.image}
            price={detailProduct.price}
            alertContent={`${detailProduct.title} was Sell sucessfully! Your Current Coin ${props.coin}`}
            desc={detailProduct.description}
            nameButton={"SELL"}
            buttonColor={"warning"}
            showAlert={true}
            onAlert={handleAlert}
            handleCoin={props.addCoin}
            />
                <div>
                    <p style={{marginTop: "20px"}}>MyProduct {'>'} Product List</p>
                    <img onClick={handleView} style={{marginTop: "-3rem", cursor:"pointer"}} height="40" width="40" className='float-end' src={listImage} alt="" />
                </div>
                <div className="container">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4">
                        {myProducts.map(((productItem: { id: React.Key | null | undefined; image: string; title: string; price: Uint8Array; description: string; }, index:any)  => {                
                            return (listView?
                                (
                                    <ListView 
                                    key={productItem.id}
                                    id ={index}
                                    onDetail={handleShow}
                                    image={productItem.image} 
                                    title={productItem.title}
                                    price={productItem.price}
                                    desc={productItem.description.length > 200? productItem.description.substring(0,200)+"...":productItem.description}
                                    />
                                ):
                                (
                                <div className="col" style={{marginTop:"20px"}}>
                                <GridView 
                                    key={productItem.id}
                                    id ={index}
                                    onDetail={handleShow}
                                    image={productItem.image} 
                                    title={productItem.title.length > 50 ? productItem.title.substring(0,50)+"...":productItem.title}
                                    price={productItem.price}
                                    desc={productItem.description.length > 110? productItem.description.substring(0,110)+"...":productItem.description}
                                    />
                                </div>
                                )
                            )
                        }))}
                    </div>
                </div>
                <FloatSubApp />
            </div>
        </div>
    );
}

const mapStateToProps = (state : any) => {
    return {
      myProduct: state.myProduct,
      coin: state.coin
    }
}

const mapDispatchToProps = (dispatch : any) => {
    return {
      deleteProduct: () => dispatch({type: "DELETE_PRODUCT", indexProduct: indexProduct, dataProduct: dataDetailProduct}),
      addCoin: () => dispatch({type: "ADD_COIN", coin: dataDetailProduct.price })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProduct);