import { legacy_createStore as createStore } from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from "./Reducers";

const persistConfig = {
    key: 'root',
    storage
  }

  
   
const persistedReducer = persistReducer(persistConfig, rootReducer)
   
let store = createStore(persistedReducer)
let persistor = persistStore(store)


  

  export default persistor;
  export {store};