import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import Header from "../Header/Header";
import Button from "../Button";
import GridView from "../GridView";
import DetailProduct from '../DetailProduct';
import FloatSubApp from '../FloatSubApp';
import {connect} from "react-redux";
import ListView from '../ListView/ListView';
import gridIcon from '../../Assets/grid.png';
import listIcon from '../../Assets/list.png';

let dataAddProduct : any;


function App(props: any) {

  // async function getProduct() {
  //   await productData;
  //   console.log(productData);
    
  // }

  const [productData, setProductData] = useState<any[]>([]);
  const [show, setShow] = useState(false);
  const [detailProduct, setDetailProduct] = useState<any>({});
  const [listView, setListView] = useState(true);
  const [listImage, setListImage] = useState(gridIcon);
  const [searchValue, setSearchValue] = useState<any>("");
  const [productTemp, setProductTemp] = useState<any[]>([]);

  useEffect(() => {
    console.log("Ini CLass" + searchValue);
    
    if(searchValue === "") {
        console.log("lagi jalan");
        getProduct();
      
    }
  });
  

  // useEffect(()=>{
  //   // console.log(searchValue);
  //   // if(searchValue.length == 0) {
  //   //   setProductTemp(productData);
  //   //   setProductData(productTemp);
  //   // }
	// }, [])

  console.log(searchValue);
  

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  function handleShow(id: any) {
    setDetailProduct(productTemp[id - 1]);
    setShow(true);
    console.log(props.myProduct);
    
  }

  function handleAlert(show: boolean) {
    setShow(show);
  }

  function handleView() {
    if(listView) {
      setListView(false);
      setListImage(listIcon);
    } else {
      setListView(true);
      setListImage(gridIcon);
    }
  }
  
  // console.log(productData);
  

  function handleSearch(searchValues : any) {
    
    setSearchValue(searchValues);

    console.log(productTemp);
    

    if(searchValue !== "") {
      let searchProductData = productTemp.filter(value => value.title.toLowerCase().includes(searchValues.toLowerCase()));
       setProductData(searchProductData);
    }
    // useEffect(() => {

      
      
      // console.log(searchProductData);
      
      // console.log(productData.filter(value => value.title.toLowerCase().includes("Fjallraven")));
      // console.log(productData[0].title.toLowerCase().includes(searchValue.toLowerCase()));
      
      // setProductData(searchProductData)
      // })
    }
    // useEffect(() => {
    //   if(searchValue != "") {
    //     let searchProductData = productTemp.filter(value => value.title.toLowerCase().includes(searchValue.toLowerCase()));
    //      setProductData(searchProductData);
    //   }
    // }, [])

    //whenever search value gets updated, we will update patience list
  

  dataAddProduct = detailProduct;
  async function getProduct() {
    try {
      const response = await axios.get('https://fakestoreapi.com/products');
      if (searchValue === "") {
          console.log("api Dipanggil");
          
          setProductData(response.data);
          setProductTemp(productData);
      }
      
    } catch (error) {
      console.error(error);
    }
  }
  // console.log(productData[0].price);
   
  return (
      <div className="App">
          <Header searchValue={handleSearch} inputValue={searchValue} />
          <div className='mainPage'>
            <DetailProduct 
            show={show} 
            onHide={handleClose}
            handleClick={props.addProduct}
            title={detailProduct.title}
            image={detailProduct.image}
            price={detailProduct.price}
            alertContent={`${detailProduct.title} was Bought sucessfully! Your Current Coin ${props.coin}`}
            desc={detailProduct.description}
            nameButton={"BUY"}
            buttonColor={"info"}
            showAlert={true}
            onAlert={handleAlert}
            handleCoin={props.reduceCoin}
            />
            <Link to="/myproduct">
              <Button color="warning" margin='0 0 0 0' onClick={() => null} text="My Product"/>
            </Link>
            <div>
              <p style={{marginTop: "20px"}}>Home {'>'} Product List</p>
              <img onClick={handleView} style={{marginTop: "-3rem", cursor:"pointer"}} height="40" width="40" className='float-end' src={listImage} alt="" />
            </div>
            <div className="container">
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4">
                {productData.map(productItem => {                
                  return (listView?
                  (
                    <ListView 
                    key={productItem.id}
                    id ={productItem.id}
                    onDetail={handleShow}
                    image={productItem.image} 
                    title={productItem.title}
                    price={productItem.price}
                    desc={productItem.description.length > 200? productItem.description.substring(0,200)+"...":productItem.description}
                    />
                  ):
                  (
                    <div className="col" style={{marginTop:"20px"}}>
                      <GridView 
                      key={productItem.id}
                      id ={productItem.id}
                      onDetail={handleShow}
                      image={productItem.image} 
                      title={productItem.title.length > 50 ? productItem.title.substring(0,50)+"...":productItem.title}
                      price={productItem.price}
                      desc={productItem.description.length > 110? productItem.description.substring(0,110)+"...":productItem.description}
                      />
                    </div>
                  )
                  )
                  })}
              </div>
            </div>
              <FloatSubApp />
          </div>
      </div>
  );
}


const mapStateToProps = (state : any) => {
  return {
    myProduct: state.myProduct,
    coin: state.coin
  }
}

const mapDispatchToProps = (dispatch : any) => {
  return {
    addProduct: () => dispatch({type: "ADD_PRODUCT", dataProduct: dataAddProduct}),
    reduceCoin: () => dispatch({type: "REDUCE_COIN", coin: dataAddProduct.price})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
