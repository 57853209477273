import React from "react";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./ListView.css"

interface productsProps {
    id: any;
    onDetail: any;
    image : string;
    title: string;
    price: BigInteger;
    desc: string;
}

function ListView(props: productsProps) {

    function handleClick() {
        props.onDetail(props.id);
    }

    return (
        <>
            <Card className="cardList" onClick={handleClick} >
                <Row style={{width: "95%"}} sm={4} md={6} lg={6}>
                    <Col sm={6} md={4} lg={3}> 
                        <img style={{marginTop: "1.3rem"}} height="180" width={200} src={props.image} alt="" />
                    </Col>
                    <Card.Body style={{margin: "1.3rem"}}>
                        <Col sm={6} md={8} lg={12}>
                            <Card.Title>{props.title}</Card.Title>
                            <p><b>${props.price}</b></p>
                            <Card.Text>{props.desc}</Card.Text>
                        </Col>
                    </Card.Body>
                </Row>
            </Card>
        </>
    )
}

export default ListView;