const globalState = {
    myProduct: [],
    coin: 500
  }
  
  console.log(globalState.myProduct);
  
  
  const rootReducer = (state = globalState, action : any) => {
    switch (action.type) {
        case "ADD_PRODUCT":
            return {
                ...state,
                myProduct: state.myProduct.concat(action.dataProduct)
             }
        case "DELETE_PRODUCT":
            return {
                ...state,
                myProduct: [...state.myProduct.slice(0, action.indexProduct),
                    ...state.myProduct.slice(action.indexProduct + 1)]
            }
        case "ADD_COIN":
            return {
                ...state,
                coin: state.coin + action.coin
            }
        case "REDUCE_COIN":
            return {
                ...state,
                coin: state.coin - action.coin
            }
        default:
            return state;
    }
  }

  export default rootReducer;