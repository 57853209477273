import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./CollectCoin.css";
import CollectDone from "./CollectDone";
import { connect } from "react-redux";

let addCoin : number;

function CollectCoin(props : any) {
    
    const [coinAward, setCoinAward] = useState("");
    const [showAward, setShowAward] = useState(false);

    function handleClick() {
        let randomAward = Math.floor(Math.random() * 3) + 1;
        if(randomAward === 1) {
            setCoinAward("Gold Coin");
            addCoin = 100;
        } else if(randomAward === 2) {
            setCoinAward("Silver Coin");
            addCoin = 50;
        } else {
            setCoinAward("Bronze Coin");
            addCoin = 20;
        }
        setShowAward(true);
        setTimeout(() => {setShowAward(false)}, 8000);
    }
    
    return ( !showAward?(
        <div>
            <Header />
            <div className="mainPage">
                <p>Collect Coins</p>
                <Container className="mainSection" fluid="sm">
                    <Row className="coinRow">
                        <Col>
                            <img width="80" height="80" src={require("../../Assets/gold-coin.png")} alt="Gold Coin" />
                            <h4>100 Coins</h4>
                        </Col>
                        <Col>
                            <img width="80" height="80" src={require("../../Assets/silver-coin.png")} alt="Gold Coin" />
                            <h4>50 Coins</h4>
                        </Col>
                        <Col>
                            <img width="80" height="80" src={require("../../Assets/bronze-coin.png")} alt="Gold Coin" />
                            <h4>20 Coins</h4>
                        </Col>
                    </Row>
                    <h3 className="hint">Click on egg to collect coins!!!</h3>
                    <img  onClick={() => {handleClick(); props.addProduct();}} width="100" height="350" className="mainImage" src={require("../../Assets/egg-full.png")} alt="" />
                </Container>
            </div>
        </div>) : (<CollectDone coinAward={coinAward}/>)
    )

}

const mapStateToProps = (state : any) => {
    return {
        coin: state.coin
    }
}

const mapDispatchToProps = (dispatch : any) => {
    return {
        addProduct: () => dispatch({type: "ADD_COIN", coin: addCoin})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectCoin);