import React from "react";
import { Link } from "react-router-dom";

function FloatSubApp() {

    return(
        <Link to="/collectCoin">
            <div style={{margin: "2rem", cursor:"pointer"}} className="position-fixed bottom-0 end-0">
                <div style={{borderRadius: "50%", backgroundColor: "#4abadf"}}>
                    <img height="80" src={require("../Assets/egg-full.png")} alt="" />
                </div>
            </div>
        </Link>
    )
}

export default FloatSubApp;