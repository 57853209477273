import React from "react";
import Header from "../../components/Header/Header";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./CollectCoin.css";

function CollectDone(props: any) {
    return (
        <div>
            <Header />
            <div className="mainPage">
                <p>Collect Coins</p>
                <Container className="mainSection" fluid="sm">
                    <Row className="coinRow">
                        <Col>
                            <img width="80" height="80" src={require("../../Assets/gold-coin.png")} alt="Gold Coin" />
                            <h4>100 Coins</h4>
                        </Col>
                        <Col>
                            <img width="80" height="80" src={require("../../Assets/silver-coin.png")} alt="Gold Coin" />
                            <h4>50 Coins</h4>
                        </Col>
                        <Col>
                            <img width="80" height="80" src={require("../../Assets/bronze-coin.png")} alt="Gold Coin" />
                            <h4>20 Coins</h4>
                        </Col>
                    </Row>
                    <h2 className="titleHint">CONGRATULATIONS!!!</h2>
                    <h3 className="hint">You Got a {props.coinAward}</h3>
                    <img  width="100" height="350" className="mainImage" src={require("../../Assets/egg-broken.png")} alt="">
                    </img>
                </Container>
            </div>
        </div>
    )

}

export default CollectDone;