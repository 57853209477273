import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import App from './components/App(Home)/App';
import reportWebVitals from './reportWebVitals';
import MyProduct from "./pages/MyProduct/MyProduct";
import CollectCoin from "./pages/CollectCoin/CollectCoin";
import { Provider } from 'react-redux';
import persistor, {store} from './configureStore';
import { PersistGate } from 'redux-persist/integration/react'
import DetailPage from './components/DetailProduct';


const router = createBrowserRouter([
  {
    path: "/",
    element: (<App />),
  },
  {
    path: "myproduct",
    element: (<MyProduct />),
  },
  {
    path: "collectcoin",
    element: (<CollectCoin />)
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <RouterProvider router={router} />
    </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
