import React, { useState } from "react";
import Button from "./Button"
import Modal from 'react-bootstrap/Modal';
import Alert from "./Alert/Alert";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { AnyTxtRecord } from "dns";

interface DetailProductProps {
    show: boolean;
    onHide: VoidFunction;
    handleClick: VoidFunction;
    handleCoin: VoidFunction;
    title: string;
    image: string;
    price: any;
    alertContent: string;
    desc: string;
    nameButton: string;
    buttonColor: string;
    showAlert: boolean;
    onAlert: any;
}

function DetailPage(props : DetailProductProps) {
    

    const [showAlert, setShowAlert] = useState(false);

    const handleCloseAlert = () => setShowAlert(false);
  // const handleShow = () => setShow(true);

    function handleShowAlert(showAlert: boolean) {
        // setDetailProduct(productData[id - 1]);
        setShowAlert(showAlert);
        props.onAlert(false);
        // console.log(props.myProduct);
        
    }
    
    return(
    <div>

    <Modal show={props.show} onHide={props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
                <Row>
                <Col xs={12} md={4}>
                    <img width="150" height="150" src={props.image} alt="" />
                    <Button color={props.buttonColor}  margin="5rem 5rem 0 3rem" onClick={() => {props.handleClick(); handleShowAlert(props.showAlert); props.handleCoin();}} text={props.nameButton}/>
                </Col>
                <Col xs={12} md={6}>
                    <p><b>${props.price}</b></p>
                    <p>{props.desc}</p>
                </Col>
                </Row>
            </Container>
        </Modal.Body>
    </Modal>
    <Alert 
    showAlert={showAlert} 
    handleCloseAlert={handleCloseAlert} 
    image={props.image} 
    title={props.title} 
    content={props.alertContent} />
    </div>
    )
}

export default (DetailPage);